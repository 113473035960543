.block {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0.75rem 0;
  cursor: pointer;

  input[type='checkbox'] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}

.label {
  font-size: 10px;
  line-height: 1.1;
  flex: 1;

  a {
    box-shadow: inset 0 -0.5px 0 0 currentColor;
  }
}

.control {
  display: block;
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: 5px;
  margin-right: 0.5rem;
  border: 1px solid var(--color-primary);

  &::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 2px;
    position: absolute;
    top: 2px;
    left: 2px;
    opacity: 0;
    transform: scale(0.8);
    transition: all 0.3s ease;
    background-color: var(--color-primary);
  }
}

.checked {
  @extend .control;

  &::after {
    opacity: 1;
    transform: none;
  }
}
