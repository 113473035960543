.page {
  background: var(--color-question5) url(../../assets/images/bg-comment.png) center 105px no-repeat;
  background-size: 100% auto;

  p {
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
}

.questionCounter {
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  color: var(--color-primary);
  font-family: var(--global-headings-font-family);
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50px;
  object-fit: cover;
}

