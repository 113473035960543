@import '/src/styles/mixins';

.page {
  h2 {
    font-size: 20px;
    @include small-viewport {
      font-size: 15px;
    }    
  }
}

.wrapper {
  max-width: 80vw;
  margin: 0 auto;
  text-align: left;
}

.label {
  display: block;
  margin-bottom: 0.25rem;

  @include small-viewport {
    font-size: 14px;
  }
}

.label_inline {
  display: inline-block;
}

.radios {
  vertical-align: -5px;;
  margin: 0 0 0 20px;
  display: inline-block;
}

.skip {
  border: 1px solid;
  background-color: transparent;
  color: var(--color-primary);
  font-family: var(--global-headings-font-family);
  height: 48px;
  border-radius: 5px;
  width: 100%;
  max-width: 70vw;
  margin-top: 10px;  
}