.page {
  text-align: center;

  h2 {
    margin-bottom: calc(var(--v-spacing) * 1.5);
  }
}

.start {
  font-size: 20px;
  font-weight: normal;
  margin: calc(var(--v-spacing) * 2) 0 0.5rem;
  color: var(--color-primary);
  text-transform: uppercase;
  font-family: var(--global-headings-font-family);
}

.steps {
  padding: 0 var(--gutters);
}

.circle {
  width: 78px;
  height: 78px;
  border-radius: 50%;
  margin: 1.5rem auto 0;
}
