@import '/src/styles/mixins';

.b-control {
  display: block;
  width: 100%;
  height: 42px;
  font-size: 13px;
  padding: 0 0.75rem;
  font-weight: normal;
  border-radius: 5px;
  margin-bottom: 0.25rem;
  border: 1px solid var(--color-primary);
  font-family: var(--global-headings-font-family);

  @include small-viewport {
    height: 36px;
  }

  &::placeholder {
    color: #adadac;
    opacity: 1;
  }

  &--textarea {
    height: 175px;
    font-size: 12px;
    padding: 0.75rem;
    font-style: italic;
  }
}
