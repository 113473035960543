.block {
  position: absolute;
  left: 0;
  top: 16px;
  width: 100%;
  z-index: 100;
}

.wrapper {
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggle {
  border: 0;
  padding: 0;
  display: block;
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: transparent;

  >span {
    display: block;
    width: 16px;
    height: 3px;
    position: absolute;
    top: 50%;
    left: 0;
    background-color: #000;
    transition: background 0.3s ease;

    // body:not([class='modal-open']) [data-whitenav='true'] & {
    //   background-color: #fff;
    // }

    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      background-color: #000;
      transition: background 0.3s ease;

      // body:not([class='modal-open']) [data-whitenav='true'] & {
      //   background-color: #fff;
      // }
    }

    &::before {
      top: -6px;
    }

    &::after {
      top: 6px;
    }
  }
}

.btn {
  border: 0;
  margin: 0;
  padding: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: transparent;

  svg {
    width: 16px;
    height: 16px;
    display: block;

    line {
      transition: all 0.3s ease;
    }

    body:not([class='modal-open']) [data-whitenav='true'] & {
      line {
        stroke: #fff;
      }
    }
  }
}

.disabled {
  opacity: 0;
  visibility: hidden;
}

.removed {
  display: none !important;
}

.logo {
  width: 65px;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 0.3s ease;
  transform: translate(-50%, -50%);
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.back {
  position: absolute;
  left: -4px;
  top: 100%;
  margin-top: 1.5rem;
}

.btns {
  position: relative;
}

.language {
  display: flex;

  li {
    list-style-type: none;
    font-size: 14px;
    cursor: pointer;
    text-transform: uppercase;

    &:not(:first-of-type) {
      margin-left: 10px;
    }

    &.active {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}