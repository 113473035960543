@import '/src/styles/mixins';

.content {
  margin-bottom: calc(var(--v-spacing) * 2);
  text-align: center;
  padding: 0 var(--gutters);

  p {
    margin: calc(var(--v-spacing) * 2) 0 0;
  }
}

.push {
  max-width: 320px;
  margin: 0 auto;

  @include small-viewport {
    width: 200px;
  }
}

.anim {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@keyframes appear {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.campaign_off {
  opacity: 0;
  animation: appear 1s ease forwards;
  animation-delay: 2s;
}