.page {
  text-align: center;

  h2 {
    margin-bottom: 2rem;
  }
}

.content {
  padding: 0 calc(var(--gutters) / 2);
}

.allies {
  width: calc(80vw + 2rem);
  max-width: 320px;
  list-style: none;
  margin: 2.375rem auto 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 90%;
    height: 1px;
    position: absolute;
    left: 5%;
    top: 50%;
    background-color: #e5e5e5;
  }

  &::after {
    transform: rotate(90deg);
  }
}

.item {
  width: 50%;
  padding: 1rem;

  a {
    display: block;
    text-decoration: none;
  }

  p {
    line-height: 1;
    font-size: 11px;
    font-weight: normal;
    font-family: var(--global-headings-font-family);
  }
}

.btn {
  padding: 0;
  margin: 0;
  border: 0;
  color: #000;
  cursor: pointer;
  text-align: center;
  background-color: transparent;
  -webkit-appearance: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

.anim {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
