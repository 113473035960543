.block {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 40;
  background-color: #fffcf7;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.3s ease;

  [class='modal-open'] & {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
}

.content {
  text-align: center;
  padding: 0 var(--gutters);

  p:not([class]) {
    line-height: 1;
    font-size: 20px;
    margin: 3rem 0 0.75rem;
  }
}

.title {
  font-size: 38px;
  line-height: 1;
  margin: 0;
}
