.block {
  width: 100%;
  max-width: 80vw;
  margin: 0 auto;
  position: relative;

  & + & {
    margin-top: 0.5rem;
  }

/*  > span::after {
    content: "";
    display: none;
    height: 14px;
    width: 14px;
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
    background: url(../../assets/images/clear.svg) center center no-repeat;
    background-size: cover;
  }*/  
}

.input,
.button {
  height: 42px;
  width: 100%;
  display: block;
  text-align: left;
  font-size: 12px;
  cursor: pointer;
  outline: 0;
  color: var(--color-primary);
  padding: 0 40px 0 20px;
  border-radius: 5px;
  font-weight: normal;
  position: relative;
  background-color: #fff;
  border: 1px solid var(--color-primary);
  font-family: var(--global-headings-font-family);
}

.input {
  &::placeholder { 
    color: var(--color-primary);
    opacity: 1; 
  }


}


.button {
  &::after {
    content: "";
    display: block;
    height: 14px;
    width: 7px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: url(../../assets/images/arrow.svg) center center no-repeat;
    background-size: cover;
  }
}

.options {
  list-style: none;
  width: 100%;
  max-height: 20vh;
  overflow: scroll;
  position: absolute;
  bottom: 100%;
  padding: 0;
  outline: 0;
  margin: 0 0 0.25rem;
  background-color: #fff;
  border-radius: 5px;
  text-align: left;
  font-weight: normal;
  font-size: 12px;
  padding: 5px 0;
  border: 1px solid var(--color-primary);
  font-family: var(--global-headings-font-family);
}


.option {
  cursor: pointer;
  padding: 6px 40px 6px 20px;
  position: relative;
  color: var(--color-primary);
  transition: background 0.3s ease;


  &::after {
    content: '';
    display: block;
    height: 14px;
    width: 14px;
    position: absolute;
    right: 20px;
    top: 50%;
    opacity: 0;
    transform: translateY(-50%);
    background: url(../../assets/images/check.svg) center center no-repeat;
    background-size: cover;
  }
}

.active {
  @extend .option;
  background-color: rgba(238, 136, 75, 0.1);
}

.selected {
  &::after {
    opacity: 1;
  }
}

