@import '/src/styles/mixins';

.image {
  //background-image: url('../../assets/images/07.jpg');
  background-size: cover;
  background-position: center center;
  display: block;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  position: absolute; // Ajoutez cette ligne
  --color-disabled: #f8ece0;
  --color-disabled-dark: #d1bfbb;

  //@include small-viewport {
  //  max-width: 240px;
  //}
}

// .image {
//   display: block;
//   width: 100%;
//   height: auto;
//   max-width: 280px;
//   margin: 0 auto;
//   --color-disabled: #f8ece0;
//   --color-disabled-dark: #d1bfbb;

//   @include small-viewport {
//     max-width: 240px;
//   }
// }

.next {
  fill: var(--color-disabled);
  stroke: var(--color-disabled-dark);
}

.end {
  [data-num] & {
    rect {
      fill: var(--color-disabled);
      stroke: var(--color-disabled-dark);

      &:not([fill='#fff']) {
        fill: var(--color-disabled-dark);
      }
    }
  }
}

.num {
  circle {
    stroke: var(--color-disabled-dark);
  }

  text {
    fill: var(--color-disabled-dark);
  }
}

.guardian2 {
  &[data-unactive='true'] {
    path:not([fill='#080809']) {
      fill: #fff;
    }

    path[fill='#080809'] {
      fill: var(--color-disabled-dark);
    }
  }
}

.guardian3 {
  &[data-unactive='true'] {
    path:not([fill='#060707']) {
      fill: #fff;
    }

    path[fill='#060707'] {
      fill: var(--color-disabled-dark);
    }
  }
}

.guardian4 {
  &[data-unactive='true'] {
    path:not([fill='#060606']) {
      fill: #fff;
    }

    path[fill='#060606'] {
      fill: var(--color-disabled-dark);
    }
  }
}

.guardian5 {
  &[data-unactive='true'] {

    path:not([fill='#232749']),
    path:not([fill='#090909']) {
      fill: #fff;
    }

    path[fill='#090909'],
    path[fill='#232749'] {
      fill: var(--color-disabled-dark);
    }
  }
}