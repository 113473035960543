.sheet {
  position: relative;
  z-index: 10;
}

.anim {
  display: block;
  width: 100%;
  z-index: -1;
  position: absolute;
  left: 0;
  bottom: calc(100% - 30px);
  height: calc(100vh - 100% + 30px);
  height: calc((var(--vh, 1vh) * 100) - 100% + 30px);

  video {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    object-fit: cover;
  }
}

.inner {
  p {
    font-size: 18px;
    line-height: 1.2;
  }
}

.subtitle {
  font-size: 15px;
  font-weight: normal;
  margin-bottom: 0.75rem;
  color: var(--color-primary);
  text-transform: uppercase;
  font-family: var(--global-headings-font-family);
}

.nav {
  list-style: none;
  margin: 0 0 1rem;
  display: flex;
  padding: 0;
  justify-content: center;
}

.wrapper {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 128px;
  height: 84px;
  position: relative;
  margin-bottom: 0.75rem;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.item {
  width: 0;
  height: 0;
  overflow: hidden;
  padding: 0.5rem 0 0 0.5rem;
  margin: 0 3px;
  border-radius: 50%;
  background-color: var(--color-question1);
}

.current {
  @extend .item;
  background-color: var(--color-primary);
}

.answers {
  list-style: none;
  margin: 1.25rem auto 0;
  max-width: calc(80vw + 1.5rem);
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.answer {
  width: calc(50% - 0.75rem);
  margin: 0.75rem 0.375rem 0;
}

.button {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 56px;
  border-radius: 5px;
  background-color: #fff;
  font-size: 12px;
  font-weight: normal;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  font-family: var(--global-headings-font-family);
}

.btn {
  margin-top: 1.5rem;
}
