.page {
  margin: 0;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  background-image: url('../../assets/images/07.jpg');
  background-size: cover;
  background-position: center center;

  * {
    margin: 0;
    padding: 0;
  }
}

.wrapper {
  padding: 0 var(--gutters);
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 240px;
  color: white;

  h2 {
    margin-bottom: 1rem;
  }

  p {
    line-height: 1.2;
    margin-bottom: calc(var(--v-spacing) * 2);
  }
}

.ally {
  width: 78px;
  height: 78px;
  border-radius: 50%;
  margin: 1.5rem auto 0;
}

.textWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bravoButton {
  margin-top: -110px;
  display: flex;
  justify-content: center;
  width: 100%;
}