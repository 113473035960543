@import '/src/styles/mixins';

.group {
  display: flex;
}

.wrapper {
  outline: none;
  margin: 0 1.5rem calc(var(--v-spacing)) 0;
}

.option {
  display: flex;
  align-items: center;
  cursor: pointer;

  @include small-viewport {
    font-size: 14px;
  }

  span {
    width: 22px;
    height: 22px;
    display: block;
    border-radius: 50%;
    margin-right: 0.75rem;
    position: relative;
    border: 1px solid #707070;

    &::before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      position: absolute;
      top: 3px;
      left: 3px;
      opacity: 0;
      transform: scale(0.8);
      border-radius: 50%;
      border: 1px solid #707070;
      transition: all 0.3s ease;
      background-color: var(--color-primary);
    }
  }
}

.active {
  @extend .option;
}

.checked {
  @extend .option;

  span {
    &::before {
      opacity: 1;
      transform: none;
    }
  }
}
