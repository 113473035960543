@import '/src/styles/mixins';

.page {
  &[data-question='3'] {
    background: var(--color-question3) url(../../assets/images/bg-opinion3.png) center center no-repeat;
    background-size: 100% auto;
  }

  &[data-question='4'] {
    background: var(--color-question4) url(../../assets/images/bg-opinion4.png) center center no-repeat;
    background-size: 100% auto;
  }

  &[data-question='5'] {
    color: #fff;
    background: var(--color-question5) url(../../assets/images/bg-opinion5.png) center center no-repeat;
    background-size: 100% auto;
  }
}

.content {
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 200px;
}

.inner {
  padding: 0 var(--gutters);

  p {
    font-size: 16px;
    line-height: 1.2;
    //margin-bottom: 20px;
  }
}


.image {
  position: relative;
  width: 75vw;
  max-width: 16rem;
  margin: 0 auto;

  @include small-viewport {
    width: 54vw;
  }

  [data-question='1'] & {
    width: 100%;
    max-width: none;
  }

  [data-question='2'] & {
    margin: calc(var(--v-spacing) * 4) auto 0;
  }

  [data-question='4'] & {
    //margin: calc(var(--v-spacing) * 2) auto 0;
    margin: 0 auto;
  }

  [data-question='5'] & {
    margin: 0 auto 0 0;

    @include small-viewport {
      margin: 0 auto;
      width: 50vw;
    }
  }
}

.imageForId5 {
  top: 20px;
}

.answer {
  font-size: 25px;
  font-weight: normal;
  margin-bottom: 1rem;
  font-family: var(--global-headings-font-family);
}

.frames {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.frame {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80%;
  background: url(../../assets/images/parallax5.png) center top no-repeat;
  background-size: 100% auto;

  &:nth-child(2) {
    height: 75%;
    background-image: url(../../assets/images/parallax4.png);
  }

  &:nth-child(3) {
    height: 65%;
    background-image: url(../../assets/images/parallax3.png);
  }

  &:nth-child(4) {
    height: 60%;
    background-image: url(../../assets/images/parallax2.png);
  }

  &:nth-child(5) {
    height: 50%;
    background-image: url(../../assets/images/parallax1.png);
  }

  [data-step='2'] & {
    transform: translateY(7%);
  }

  [data-step='3'] & {
    transform: translateY(14%);
  }

  [data-step='4'] & {
    transform: translateY(21%);
  }

  [data-step='5'] & {
    transform: translateY(28%);
  }
}

.ariane {
  font-size: 18px;
  font-weight: 800;
  color: var(--color-primary);
  font-family: var(--global-headings-font-family);
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50px;
  object-fit: cover;
}

.ariane5 {
  font-size: 18px;
  font-weight: 800;
  color: var(--color-primary);
  font-family: var(--global-headings-font-family);
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  top: -420px;
  object-fit: cover;
}

.counter5 {
  margin-top: 100px;
}

.title {
  font-size: 19px;
  font-weight: 800;
  color: var(--color-primary);
  text-transform: uppercase;
  font-family: var(--global-headings-font-family);
  margin: 0px;
}

.subtitle {
  font-size: 18px;
  font-weight: 800;
  color: var(--color-primary);
  font-family: var(--global-headings-font-family);
  margin-top: 0px;
  margin-bottom: 5px;
}

.sheet {
  position: relative;
  z-index: 10;
}


.wrapper {
  min-height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.anim {
  display: block;
  width: 100%;
  z-index: -1;
  position: absolute;
  left: 0;
  bottom: calc(100% - 30px);
  height: calc(100vh - 100% + 30px);
  height: calc((var(--vh, 1vh) * 100) - 100% + 30px);
}

.continue {
  margin-bottom: 80px;
}

@media (max-height: 670px) {
  .continue {
    margin-bottom: 30px;
  }
}


.comment {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.btn5 {
  margin-top: 250px;
}

.commentArea {
  position: absolute;
  bottom: 150px;
  border: solid 2px var(--color-primary);
  height: 200px;
  width: 85%;
  border-radius: 8px;
  left: 50%;
  transform: translateX(-50%);
}

.commentInput {
  width: 100%;
  height: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  box-sizing: border-box;
  outline: none;
  resize: none;
  font-family: 'Spectral', serif;

  &::placeholder {
    font-style: italic;
  }
}