.wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: 100% auto;
}

// .wrapperForId5 {
//   width: 100%;
//   position: relative;
//   overflow: hidden;
// }

// .innerForId5 {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 85%;
//   height: 95%;
//   background-size: 100% auto;
// }