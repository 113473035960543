@import 'sanitize.css';

/* Variables */

:root {
  --vh: 1vh;

  --color-primary: #ec7b38;
  --color-secondary: #005ca7;

  --color-question1: #ffe0d8;
  --color-question2: #d9f3ff;
  --color-question3: #b2e6c8;
  --color-question4: #ede7aa;
  --color-question5: #232749;

  --color-ally1: #005ca7;
  --color-ally2: #ff9f00;
  --color-ally3: #ffa8db;
  --color-ally4: #ccd792;

  --v-spacing: 1rem;
  --bottom-padding: 1.5rem;
  --top-padding: 5rem;
  --gutters: 1.5rem;
  --wrapper-max-width: 22.5rem;
  --global-font-family: 'Spectral', serif;
  --global-headings-font-family: 'Arvo', serif;
}

@media (max-height: 42.5em) {
  :root {
    --v-spacing: 0.5rem;
  }
}

/* Global */

html,
body {
  overscroll-behavior-y: contain;
  overscroll-behavior: none;
}

body {
  font-weight: 300;
  font-family: var(--global-font-family);
  background-color: #fffcf7;
}

h2 {
  font-size: 25px;
  text-align: center;
  font-weight: normal;
  margin: 0 0 12px;
  line-height: 1.2;
  font-family: var(--global-headings-font-family);
}

p {
  margin: 0 0 12px;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

/* Blocks */

@import 'blocks/wrapper';
@import 'blocks/btn';
@import 'blocks/bottom-sheet';
@import 'blocks/control';
@import 'blocks/wysiwyg';

/* Utils */

.u-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.u-mb-1 {
  margin-bottom: 0.25rem;
}

.u-mb-2 {
  margin-bottom: 0.5rem;
}

.u-mb-3 {
  margin-bottom: 0.75rem;
}

.u-mb-4 {
  margin-bottom: 1rem;
}

.u-mb-5 {
  margin-bottom: 1.25rem;
}

.u-mb-6 {
  margin-bottom: 1.5rem;
}

.u-mb-7 {
  margin-bottom: 1.75rem;
}

.u-mb-8 {
  margin-bottom: 2rem;
}

.u-mt-1 {
  margin-top: 0.25rem;
}

.u-mt-2 {
  margin-top: 0.5rem;
}

.u-mt-3 {
  margin-top: 0.75rem;
}

.u-mt-4 {
  margin-top: 1rem;
}

.u-mt-5 {
  margin-top: 1.25rem;
}

.u-mt-6 {
  margin-top: 1.5rem;
}

.u-bg-1 {
  background-color: var(--color-question1);
}

.u-bg-2 {
  background-color: var(--color-question2);
}

.u-bg-3 {
  background-color: var(--color-question3);
}

.u-bg-4 {
  background-color: var(--color-question4);
}

.u-bg-5 {
  background-color: var(--color-question5);
}

.u-ally {
  background-color: var(--color-ally1);

  [data-ally='2'] & {
    background-color: var(--color-ally2);
  }

  [data-ally='3'] & {
    background-color: var(--color-ally3);
  }

  [data-ally='4'] & {
    background-color: var(--color-ally4);
  }
}

.animal2 {
  position: relative;
  left: 7px;
}