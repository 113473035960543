.block {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
}

.item {
  outline: 0;
  margin: 0 0.25rem;
}

.icon {
  cursor: pointer;
  display: block;
  width: 42px;
  height: 47px;
  background: url(../../assets/images/icon-rating.png) center top no-repeat;
  background-size: 100% auto;
}

.active {
  @extend .icon;
  background-position: center bottom;
}

.checked {
  @extend .icon;
  background-position: center bottom;
}

.label {
  font-weight: normal;
  margin-top: 1.5rem;
  font-size: 20px;
  min-height: 30px;
  color: var(--color-primary);
  font-family: var(--global-headings-font-family);
}
