.b-bottom-sheet {
  width: 100%;
  text-align: center;

  &__content {
    background-color: #fff;
    border-radius: 30px 30px 0 0;
    padding: 2rem var(--gutters) var(--bottom-padding);
  }

  &__btn {
    margin-top: calc(var(--v-spacing) * 2);
  }
}
