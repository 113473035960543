.block {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 200;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-10%);
  padding: 1.25rem var(--gutters);
  background-color: #fffcf7;

  [class='menu-open'] & {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
}

.name {
  font-size: 20px;
  padding-left: 1.5rem;
  font-weight: normal;
  color: var(--color-primary);
  position: relative;
  font-family: var(--global-headings-font-family);
}

.close {
  border: 0;
  overflow: hidden;
  padding: 24px 0 0 24px;
  margin: 0;
  width: 0;
  height: 0;
  cursor: pointer;
  background-color: transparent;
  position: absolute;
  right: 24px;
  top: 16px;
  
  &::after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url(../../assets/images/close.svg) center center no-repeat;
    background-size: cover;
  }
}

.content {
  font-size: 20px;
  padding-left: 1.5rem;
  margin-top: 2.5rem;

  a {
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      transform: scaleX(0);
      transform-origin: left center;
      transition: transform 0.3s ease;
      background-color: var(--color-primary);
    }

    @media (any-hover: hover) {
      &:hover::after {
        transform: none;
      }
    }
  }
}

.menu {
  margin: 0 0 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #000;

  li:not(:first-child) {
    margin-top: 1.5rem;
    span {
      font-size: 14px;
    }
  }

  li {
    span.active {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}
