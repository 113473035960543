.breadcrumb-container {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 5px;
    left: 0;
}

.breadcrumb {
    display: flex;
    align-items: center;
    width: 65%;
    justify-content: space-between;
}

.segment {
    flex-grow: 1;
    height: 2px;
    background-color: #ec7b38;
    z-index: 1;
}

.point {
    border: 2px solid #ec7b38;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    background-color: transparent;
    z-index: 2;
}

.point.active {
    border: none;
    width: 50px;
    height: 50px;
    background-image: none;
    background-size: 130%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    position: relative;
}

.point.active::before {
    content: '';
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    background-color: #ec7b38;
    transform: translateY(-50%);
    z-index: 0;
    left: 0;
}

.point.active::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    background-color: #ec7b38;
    transform: translateY(-50%);
    z-index: 0;
    left: 0;
}

.inner-point {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 5px;
    height: 5px;
    background-color: #ec7b38;
    border-radius: 50%;
    margin: auto;
    z-index: 2;
}

.point.active .inner-point {
    display: none;
}

.point.active .img-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: 120%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
}

.point.last {
    position: relative;
    z-index: 3;
}

.point.active.last::after,
.point.active.last::before {
    right: 10px;
    left: auto;
}

.point.first {
    position: relative;
    z-index: 3;
}

.point.active.first::before,
.point.active.first::after {
    left: 10px;
    right: auto;
}
