@import '/src/styles/mixins';

.b-btn {
  color: #fff;
  border: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 56px;
  font-size: 15px;
  margin: 0 auto;
  max-width: 70vw;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  background-color: var(--color-primary);
  font-weight: normal;
  transition: opacity 0.3s ease;
  font-family: var(--global-headings-font-family);

  @include small-viewport {
    height: 48px;
  }

  &+& {
    margin-top: 0.75rem;
  }

  &--secondary {
    border: 1px solid #000;
    color: #000;
    background-color: transparent;
  }

  &--full {
    max-width: none;
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}