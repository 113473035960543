.b-wysiwyg {
  line-height: 1.2;

  h1 {
    font-size: 22px;
    margin: 0 0 2.5rem;
    text-align: center;
    font-weight: normal;
    font-family: var(--global-headings-font-family);
  }

  h2,
  h3 {
    font-size: 18px;
    font-weight: normal;
    text-align: left;
    margin: 2rem 0 1rem;
    font-family: var(--global-headings-font-family);
  }

  h3 {
    font-size: 16px;
  }

  p {
    margin-bottom: 1.25rem;
  }

  a {
    box-shadow: 0 1px #000;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: -1rem 0 1.25rem;

    li {
      position: relative;
      padding-left: 1rem;

      &::before {
        content: '-';
        position: absolute;
        left: 0;
      }
    }
  }
}
