.b-wrapper {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  position: relative;
  z-index: 10;
  flex-direction: column;
  justify-content: flex-end;
  padding: var(--top-padding) 0 var(--bottom-padding);

  &--no-padding {
    padding: 0;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--center {
    justify-content: center;
  }

  &--top {
    height: auto;
    justify-content: flex-start;
  }

  &__btn {
    margin-top: 1rem;
  }

  &__content {
    padding: 0 var(--gutters);
  }
}
