@import '/src/styles/mixins';

.content {
  background: url(../../assets/images/illu-end.png) no-repeat;
  background-position: center;
  background-size: cover;
  justify-content: flex-start !important;
  padding-top: 65px  !important;

  p {
    
    text-align: center;
    color: white;      
    font-size: 17px;
    line-height: 20px;
    margin: 0 10%;

  }

  a {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 8vh;
  }
}